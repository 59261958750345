import React from 'react';

import { Modal, ModalContent, ModalFooter } from 'common/components/Modal';
import GateableButton from 'common/components/GateableButton';
import Button from 'common/components/Button';
import * as gateApi from 'common/core/config_gates/provisions';

class CommitUpdateButton extends React.Component {
  state = {
    gatesForCurrentChecks: [],
    modalHidden: true
  };

  async componentDidMount() {
    const gates = await this.fetchAllGates();
    const checks = CommitUpdateButton.getCurrentChecks();
    const gatesForCurrentChecks = gates.filter((gate) => checks.includes(gate.provisionLookup));

    this.setState({ gatesForCurrentChecks });
  }

  static getCurrentChecks() {
    return window.socrata.checkLookups;
  }

  fetchAllGates = async () => {
    try {
      return await gateApi.getAllGates();
    } catch (error) {
      console.error(`fetchAllGates error: ${error}`);
    }
  };

  showModal = () => this.setState({ modalHidden: false });
  hideModal = () => this.setState({ modalHidden: true });

  render() {
    const { gatesForCurrentChecks, modalHidden } = this.state;

    const modal = modalHidden || (
      <Modal onDismiss={this.hideModal}>
        <ModalContent>
          <p>More than one check is gated. Please select at most ONE check with a gate, and try again.</p>
          <p>Gated checks:</p>
          <ul>
            {gatesForCurrentChecks.map((gate) => <li key={gate.provisionLookup}>{gate.provisionLookup}</li>)}
          </ul>
        </ModalContent>
        <ModalFooter>
          <Button onClick={this.hideModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );

    if (gatesForCurrentChecks.length > 1) {
      return (
        <div>
          {modal}
          <Button onClick={this.showModal}>Commit Update</Button>
        </div>
      );
    }

    const commitUpdate = () => {
      document.getElementById('commit-bulk-ops').click(); // jankiness until the whole page can be converted to React
      return true;
    };

    return (
      <GateableButton gate={gatesForCurrentChecks[0]} onClick={commitUpdate}>
        Commit Update
      </GateableButton>
    );
  }
}

export default CommitUpdateButton;
